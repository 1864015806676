<template>
    <div class="wrapper">
        <div class="columns">
            <div class="hide-filter">
                <calendar-filter @change-date="selectedDate = $event"
                                 @update-selection="calendars = $event;"/>
            </div>
            <div class="column is-10-desktop is-12-tablet is-12-mobile">
                <enso-calendar-index :date="selectedDate"
                               :calendars="calendars"
                               ref="calendar"
                               @edit-event="event = $event || {}"/>
            </div>
        </div>
        <event-form :event="event"
                    @submit="reloadEvents"
                    @close="event = null"
                    @destroy="reloadEvents"
                    v-if="event"/>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import CalendarFilter from '@enso-ui/calendar/src/bulma/pages/calendar/components/CalendarFilter.vue';
import EventForm from '@enso-ui/calendar/src/bulma/pages/calendar/components/EventForm.vue';
import EnsoCalendarIndex from '@components/user/EnsoCalendarIndex.vue';

export default {
    name: 'Index',

    components: { EnsoCalendarIndex, CalendarFilter, EventForm },

    inject: ['errorHandler', 'route'],

    data: () => ({
        event: null,
        selectedDate: null,
        calendars: [],
    }),

    methods: {
        ...mapMutations('layout', ['showFooter', 'hideFooter']),
        reloadEvents() {
            this.$refs.calendar.fetch();
            this.event = null;
        },
    },
};
</script>
<style>
.hide-filter{
    display: none;
}
</style>
